<template>
  <div class="companies">
    <MobileTable
      v-model:selection="store.state.notice.selectedNotices"
      :label="$route.name"
      :columns="notice.columns"
      :data="store.state.notice.notices"
    />
    <DesktopTable
      v-model:selection="store.state.notice.selectedNotices"
      :label="$route.name"
      :columns="notice.columns"
      :data="store.state.notice.notices"
    />
    <Actions :items="notice.actions" />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import { onBeforeMount, reactive } from "vue";
import store from "../../store";
import { openMode, actions } from "../../constants";

export default {
  components: { MobileTable, DesktopTable, Actions },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      router.push("/noticias/nuevo");
    };
    const onDetail = async () => {
      if (store.state.notice.selectedNotices.length !== 1) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else {
        store.commit("setOpenMode", openMode.DETAIL);
        router.push("/noticias/nuevo");
      }
    };
    const onRemove = async () => {
      if (store.state.notice.selectedNotices.length !== 1) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else {
        store.commit("setLoading", true);
        const response = await store.dispatch(
          actions.noticeActions.delete,
          store.state.notice.selectedNotices[0].id
        );
        if (response.ok) {
          store.commit("setSelectedNotices", []);
          await store.dispatch(actions.noticeActions.findAll, {
            buildingId: store.state.general.selectedBuilding,
          });
          store.commit("setLoading", false);
        } else {
          store.commit("setLoading", false);
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
        }
      }
    };

    const notice = reactive({
      columns: [
        {
          field: "title",
          header: "Título",
          sort: true,
        },
        {
          field: "createdAt",
          header: "Fecha de Creación",
          sort: true,
          date: true,
        },
        {
          field: "link",
          header: "Link de Conferencia",
          sort: true,
        },
      ],
      actions: [
        {
          name: "Nuevo",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/noticias/nuevo"),
        },
        {
          name: "Detalle",
          action: onDetail,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/noticias/nuevo"),
        },
        {
          name: "Eliminar",
          action: onRemove,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/noticias/nuevo"),
        },
      ],
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedNotices", []);
      const finded = store.state.general.breadcrumbs.find(
        (item) => item.to === route.path
      );
      if (!finded)
        store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(actions.noticeActions.findAll, {
        buildingId: store.state.general.selectedBuilding,
      });
      store.commit("setLoading", false);
    });

    return { store, notice };
  },
};
</script>

<style scoped></style>
